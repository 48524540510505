/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.content-thumbnail {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media (max-width: 768.98px) {
    .content-thumbnail {
      margin-bottom: 1.5rem; } }
  .content-thumbnail-badges {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    letter-spacing: 0.01em;
    list-style: none;
    margin: 0 0 1rem;
    padding: 0; }
    @media (min-width: 769px) {
      .content-thumbnail-badges {
        margin: 0 0 0.75rem; } }
    .content-thumbnail-badges li {
      color: #004987;
      background-color: var(--color-backup-light-blue);
      font-family: "BrandonGrotesque-WebMedium";
      font-size: 0.6875rem;
      margin: 0 0.75rem 0 0;
      padding: 0.125rem 0.25rem;
      border-radius: 0.25rem;
      text-transform: uppercase; }
      .content-thumbnail-badges li:last-child {
        margin-right: 0; }
  .content-thumbnail-body.first {
    -ms-flex-order: 0;
        order: 0; }
  .content-thumbnail-body.last {
    -ms-flex-order: 2;
        order: 2; }
  .content-thumbnail-cta-container {
    margin: 1.25rem 0;
    -ms-flex: 1 0 100%;
        flex: 1 0 100%; }
  .content-thumbnail--center, .content-thumbnail-link--center {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center; }
  .content-thumbnail-figure .content-thumbnail-link {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem; }
    .content-thumbnail-figure .content-thumbnail-link img {
      border-radius: 1.25rem;
      display: block;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%; }
  .content-thumbnail--left, .content-thumbnail-link--left {
    -ms-flex-pack: start;
        justify-content: flex-start;
    text-align: left; }
  .content-thumbnail--right, .content-thumbnail-link--right {
    -ms-flex-pack: end;
        justify-content: flex-end;
    text-align: right; }
  .content-thumbnail-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-direction: column;
        flex-direction: column;
    width: calc(100% - 10px); }
  .content-thumbnail-description {
    margin-top: 0.25rem; }
    .content-thumbnail-description p {
      font-size: 0.875rem;
      line-height: 150%;
      color: #5e656b; }
      @media (min-width: 1024px) {
        .content-thumbnail-description p {
          font-size: 1rem; } }
      .content-thumbnail-description p a {
        color: #5e656b; }
        .content-thumbnail-description p a:active {
          color: #004987; }
  .content-thumbnail-readmore {
    display: none; }
  .content-thumbnail-icon {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.625rem;
    border-radius: 50%;
    background-color: var(--color-backup-light-blue); }
    .content-thumbnail-icon:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.47021 1.1369C6.21052 0.877208 5.78948 0.877207 5.52979 1.1369C5.27027 1.39642 5.27008 1.81714 5.52937 2.0769L8.78 5.33335H1.33333C0.965145 5.33335 0.666668 5.63183 0.666668 6.00002C0.666668 6.36821 0.965145 6.66669 1.33333 6.66669H8.78L5.52937 9.92314C5.27008 10.1829 5.27027 10.6036 5.52979 10.8631C5.78948 11.1228 6.21052 11.1228 6.47021 10.8631L10.6262 6.70713C11.0168 6.3166 11.0168 5.68344 10.6262 5.29291L6.47021 1.1369Z' fill='%23004987'/%3E%3C/svg%3E%0A");
      background-size: 0.8125rem;
      background-repeat: no-repeat;
      background-position: center;
      height: 1.5rem;
      width: 1.5rem; }
  .content-thumbnail-title {
    letter-spacing: 0.01em;
    text-transform: none;
    -ms-flex: 2;
        flex: 2; }
  .content-thumbnail-figure {
    margin: 0; }
  .content-thumbnail-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    width: 100%; }
    .content-thumbnail-link:hover {
      text-decoration: none;
      color: var(--color-secondary); }
      .content-thumbnail-link:hover .content-thumbnail-icon {
        background-color: var(--color-primary); }
        .content-thumbnail-link:hover .content-thumbnail-icon:after {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDcwMjEgMS4xMzY5QzYuMjEwNTIgMC44NzcyMDggNS43ODk0OCAwLjg3NzIwNyA1LjUyOTc5IDEuMTM2OUM1LjI3MDI3IDEuMzk2NDIgNS4yNzAwOCAxLjgxNzE0IDUuNTI5MzcgMi4wNzY5TDguNzggNS4zMzMzNUgxLjMzMzMzQzAuOTY1MTQ1IDUuMzMzMzUgMC42NjY2NjggNS42MzE4MyAwLjY2NjY2OCA2LjAwMDAyQzAuNjY2NjY4IDYuMzY4MjEgMC45NjUxNDUgNi42NjY2OSAxLjMzMzMzIDYuNjY2NjlIOC43OEw1LjUyOTM3IDkuOTIzMTRDNS4yNzAwOCAxMC4xODI5IDUuMjcwMjcgMTAuNjAzNiA1LjUyOTc5IDEwLjg2MzFDNS43ODk0OCAxMS4xMjI4IDYuMjEwNTIgMTEuMTIyOCA2LjQ3MDIxIDEwLjg2MzFMMTAuNjI2MiA2LjcwNzEzQzExLjAxNjggNi4zMTY2IDExLjAxNjggNS42ODM0NCAxMC42MjYyIDUuMjkyOTFMNi40NzAyMSAxLjEzNjlaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo="); }

.product.pd-slide .content-thumbnail-container {
  margin-right: 0.8125rem; }
